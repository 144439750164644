import axios from 'axios';
import { get } from 'lodash';
import { isGuestPage } from '../helpers/navigation';

var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

api.interceptors.request.use((config) => {
    let conf = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json',
        }
    }

    //Add cache variable to all get requests
    if (config.method === "get") {
        conf.params = {
            ...get(conf, "params", {}),
            cache: process.env.REACT_APP_API_CACHE
        }
    }
    return conf;
})

api.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (get(error, "response.status", false) === 401 && !isGuestPage()) {
        window.location.href = process.env.PUBLIC_URL || "/login?redirect=" + window.location.pathname;
    }
    return Promise.reject(error);
})

export default api;