import { useEffect, useState } from "react";
import api from "../../actions/api";
import { getErrorCode } from "../../reducers/Helper";
import { transformErrors } from "../../helpers/input";
import { getAbortController } from "../../helpers/api";

export const useFetch = (url, disabled = false, axiosProps = {}) => {
    const { method = "get", params: paramsData = {}, responseType = "json", headers } = axiosProps;
    const [params, setParams] = useState({
        fetching: false,
        data: false,
        errors: false,
        serverError: false,
        status: false,
        loaded: false
    });

    useEffect(() => {
        const abortController = getAbortController();

        const fetchData = async () => {
            setParams(prevState => {
                return {
                    ...prevState,
                    loaded: false,
                    errors: false,
                    fetching: true,
                    status: false,
                    serverError: false
                }
            })
            api({
                method,
                responseType,
                url,
                data: (method !== "get" ? paramsData : {}),
                params: {
                    ...(method === "get" ? paramsData : {})
                },
                headers,
                signal: abortController.signal
            })
                .then(response => {
                    setParams(prevState => {
                        return {
                            ...prevState,
                            fetching: false,
                            loaded: true,
                            status: response.status,
                            data: response.data
                        }
                    })
                })
                .catch(error => {
                    setParams(prevState => {
                        return {
                            ...prevState,
                            fetching: error.message === 'canceled' ? true : false,
                            errors: error.message,
                            status: getErrorCode(error),
                            serverError: transformErrors(error),
                            loaded: true
                        }
                    })
                })
        }

        if (disabled === false && url) {
            fetchData();
        }

        return () => {
            setParams(prevState => {
                return {
                    ...prevState,
                    fetching: false,
                }
            })
            abortController.abort();
        };
    }, [url, disabled]);

    return params;
}