import { getAbortController } from "../helpers/api";
import api from "./api";

export function itemActions({ attribute = "form", url, action, type, data = {}, dispatch = false }) {
    let abortController = getAbortController();
    if (dispatch === false) {
        return dispatch => {
            dispatch({
                type: "CRUD_ITEM",
                payload: getPayload({ url, type, data, abortController }),
                meta: { attribute, action, abortType: type, abortController }
            })
        }
    }
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CRUD_ITEM",
            payload: getPayload({ url, type, data, abortController }),
            meta: { attribute, action, abortType: type, abortController }
        }).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function updateItem({ attribute = "form", data }) {
    return dispatch => {
        dispatch({
            type: "CRUD_ITEM_UPDATE",
            payload: { data },
            meta: { attribute }
        })
    }
}

export function resetItem({ attribute = "form" }) {
    return dispatch => {
        dispatch({
            type: "CRUD_ITEM_RESET",
            meta: { attribute }
        })
    }
}

export function abortItems({ attribute = "form" }) {
    return dispatch => {
        dispatch({
            type: "CRUD_ITEM_ABORT",
            meta: { attribute }
        })
    }
}

const getPayload = ({ url, type, data, abortController }) => {
    const { id = false, ...otherData } = data || {};
    const urlPath = url + (id === false ? "" : "/" + id);

    if (type === "get") {
        return api.get(urlPath, {
            params: otherData,
            signal: abortController.signal
        })
    }
    if (type === "create") {
        return api.post(url, otherData, {
            signal: abortController.signal
        })
    }
    if (type === "update") {
        return api.put(urlPath, otherData, {
            signal: abortController.signal
        })
    }
    if (type === "patch") {
        return api.patch(urlPath, otherData, {
            signal: abortController.signal
        });
    }
    if (type === "restore") {
        return api.patch(urlPath, otherData, {
            signal: abortController.signal
        })
    }
    if (type === "delete") {
        return api.delete(urlPath, {
            data: otherData,
            signal: abortController.signal
        })
    }
}