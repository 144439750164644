import React from 'react';
import style from './style.module.scss';
import { getCurrentClubColor } from '../../helpers/storage';

const getSize = (size) => {
    if (size === "large") {
        return {
            width: 36,
            height: 36
        }
    }
    if (size === "ultra-large") {
        return {
            width: 50,
            height: 50
        }
    }
    if (size === "tiny") {
        return {
            width: 14,
            height: 14
        }
    }
    if (size === "nano") {
        return {
            width: 10,
            height: 10
        }
    }
    return {
        width: 20,
        height: 20
    }
}

const Spinner = ({ color = getCurrentClubColor(), size = 'small', className = "" }) => {
    return (
        <div className={`${style.spinner}${className ? ` ${className}` : ``}`} style={getSize(size)}>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
            <div className={style.spinnerBlade} style={{ backgroundColor: color }}></div>
        </div>
    );
}
export default Spinner;