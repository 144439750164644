import React, { useEffect, useRef, useState } from "react";
import { get, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import $ from 'jquery-slim';

import Spinner from "../components/Spinner";

import Media from "../components/Media";
import Center from "../components/Center";
import Link from "../components/Link";
import MobileMenu from "./MobileMenu";
import CalcHeight from "./CalcHeight";
import Logout from "./Logout";
import Notification from "./Notification";
import NotificationCount from "./Notification/Count";
import PAMenu from "./PAMenu";
import TopupBalance from "../pages/MyAccount/Payment/TopupBalance";
import { scrollToTop } from "../helpers/animation";
import { getUserClubStatus, getUserOtherClubs, isFiveHS, isOswalds, isOswaldsAndNicosMember } from "../helpers/storage";
import { isPA, isSuspended } from "../helpers/hierarchy";
import { fivehsGoldLogo, oswaldsLogo, maximesLogo } from "../helpers/images";

import style from './style.module.scss';
import { Spacer } from "../components/Elements";

let logoutTimer = null;
const Header = ({ data, loaded, fetching }) => {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [loadBalance, setLoadBalance] = useState(Date.now());
    const storage = useSelector((state) => get(state, 'storage', {}));
    const isOswaldsAndNicos = isOswaldsAndNicosMember(storage);
    const { screenWidth } = storage;
    const { fullname, salesforce_meta = {} } = get(storage, 'data', {});
    const headerRef = useRef(null);
    const { pathname, search } = useLocation();
    const [popup, setPopup] = useState(false);
    const status = getUserClubStatus(salesforce_meta);
    const hideHeader = search.includes("view=terms");

    useEffect(() => {
        setOpenMobileMenu(false);
        scrollToTop();
    }, [pathname]);

    useEffect(() => {
        if (fullname) {
            $('html').addClass('loggedIn');
        } else {
            $('html').removeClass('loggedIn');
        }
        return (() => {
            $('html').removeClass('loggedIn');
        });
    }, [fullname]);

    const getLogo = () => {
        if (isFiveHS()) {
            return fivehsGoldLogo;
        } else if (isOswalds()) {
            return oswaldsLogo;
        }
        else {
            return maximesLogo;
        }
    };

    if (hideHeader) {
        return (
            <>
                <Spacer /><Spacer />
                <div className={style.clubLogo}>
                    <Media src={getLogo()} normalImage={true} width={1300} />
                </div>
            </>
        );
    }

    const MenuItems = ({ links = [], status }) => {
        return (
            <div className={style.wrap}>
                {links && links.length > 0 && links.map((link) => {
                    if (isSuspended(status) && !['/logout', '/my-profile'].includes(get(link, 'url'))) {
                        return null;
                    }
                    if (get(link, 'url') === "/logout") {
                        return (
                            <Link className={`${style.item}`} key={get(link, 'label')} onClick={() => {
                                setOpenMobileMenu(false);
                                clearTimeout(logoutTimer);
                                logoutTimer = setTimeout(() => {
                                    setPopup('logout');
                                }, 401)
                            }}>
                                <span className={style.label}>{get(link, 'label')}</span>
                            </Link>
                        )
                    }
                    return (
                        <Link className={`${style.item}${pathname === get(link, 'url') ? ` ${style.active}` : ``}`} key={get(link, 'label')} to={get(link, 'url')}>
                            <span className={style.label}>{get(link, 'label')}</span>
                        </Link>
                    )
                })}
            </div>
        )
    }

    const BurgerMenu = ({ className = "" }) => {
        return (
            <div className={`${style.burger}${className ? ` ${className}` : ``}`}>
                <div className={style.icons}>
                    {openMobileMenu === true &&
                        <Link className={`${style.iItem} ${style.menu}`} onClick={() => { setOpenMobileMenu(false) }}>
                            <i className="fa-solid fa-xmark"></i>
                        </Link>
                    }
                    {openMobileMenu === false &&
                        <Link className={`${style.iItem} ${style.menu}`} onClick={() => { setOpenMobileMenu(true) }}>
                            <i className="fa-solid fa-bars"></i>
                        </Link>
                    }
                </div>
            </div>
        );
    }

    const { logo = {}, links = [] } = data;
    const menuItems = <MenuItems links={links} status={status} />;
    const userClubs = getUserOtherClubs(salesforce_meta);

    return (
        <>
            <div className={style.header} ref={headerRef}>
                {get(salesforce_meta, 'Name') &&
                    <div className={style.profileBar}>
                        <Center className={style.name}>
                            <span>Logged in as</span>
                            <Link to="/my-profile">
                                <span>{get(salesforce_meta, 'Name')}</span>
                            </Link>
                        </Center>
                    </div>
                }
                <div className={`${style.outer} ${style.desktop}`}>
                    <Center className={`${style.inner}${openMobileMenu ? ` ${style.hasMobileMenu}` : ``}`}>
                        {loaded === false && fetching === true && <Spinner />}
                        {loaded === true && fetching === false &&
                            <>
                                {screenWidth <= 1400 && <BurgerMenu />}
                                <div className={style.barWrap}>
                                    {!isPA(storage) && !isEmpty(userClubs) && screenWidth > 1400 && <BurgerMenu />}
                                    <div className={style.logo}>
                                        <Link className={style.img} to={isSuspended(status) ? "/my-profile" : "/dashboard"}>
                                            <Media src={logo} normalImage={true} width={260} />
                                        </Link>
                                    </div>
                                </div>
                                <div className={style.menu}>
                                    {menuItems}
                                    <div className={style.icons}>
                                        <Link className={`${style.iItem} ${style.notify}`} onClick={() => setPopup('notification')}>
                                            <i className="fa-regular fa-bell"></i>
                                            <NotificationCount loadOnMount={popup || pathname} />
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }
                    </Center>
                </div>
                {screenWidth > 1400 &&
                    <div className={style.paMenu}>
                        <PAMenu setPopup={setPopup} loadBalance={loadBalance} setOpenMobileMenu={setOpenMobileMenu} />
                    </div>
                }
                <MobileMenu openMobileMenu={openMobileMenu} links={links} menuItems={menuItems} closeMenu={() => setOpenMobileMenu(false)} setPopup={setPopup} loadBalance={loadBalance} setOpenMobileMenu={setOpenMobileMenu} />
            </div>
            {fullname && <CalcHeight headerRef={headerRef} loaded={loaded} />}
            {popup === "logout" && <Logout onClose={() => setPopup(false)} />}
            {popup === "notification" && <Notification onClose={() => setPopup(false)} />}
            {['topup-balance-nico', 'topup-balance'].includes(popup) === true && <TopupBalance onClose={() => { setPopup(false); setLoadBalance(Date.now()) }} initialFormVal={isOswaldsAndNicos ? { for: popup === "topup-balance-nico" ? "nicos" : "oswalds" } : {}} />}
        </>
    )
}
export default Header;