import React, { Suspense, lazy } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/font.css';

import Layout from './layout';
import Guest from './layout/Guest';
import Static from './layout/Static';
import ExceptionHandler from './ExceptionHandler';

const Page = lazy(() => import('./pages/Page'));
const Login = lazy(() => import('./pages/Guest/Login'));
const TwoStepAuth = lazy(() => import('./pages/Guest/TwoStepAuth'));
const ResetPassword = lazy(() => import('./pages/Guest/ResetPassword'));
const ChangePassword = lazy(() => import('./pages/Guest/ChangePassword'));
const LoggingIn = lazy(() => import('./pages/Guest/LoggingIn'));
const AccountSetup = lazy(() => import('./pages/MyAccount/Setup'));
const MemberSelect = lazy(() => import('./pages/MyAccount/MemberSelect'));
const MyAccount = lazy(() => import('./pages/MyAccount'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const TicketPayment = lazy(() => import('./pages/Page/TicketPayment'));
const IdleTimer = lazy(() => import('./layout/IdleTimer'));
const SwitchingClub = lazy(() => import('./pages/Page/SwitchingClub'));

const SuspendPage = (element, fallback = <></>) => {
  return (
    <Suspense fallback={fallback}>
      {element}
    </Suspense>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <ExceptionHandler>
        <ToastContainer />
        <IdleTimer />
        <Routes>
          <Route element={<Guest />}>
            <Route path="/login" element={SuspendPage(<Login />)} />
            <Route path="/2fa-verification" element={SuspendPage(<TwoStepAuth />)} />
            <Route path="/reset-password" element={SuspendPage(<ResetPassword />)} />
            <Route path="/change-password/:hash" element={SuspendPage(<ChangePassword />)} />
            <Route path="/setup-password/:hash" element={SuspendPage(<ChangePassword setup={true} />)} />
            <Route path="/logging-in/:hash" element={SuspendPage(<LoggingIn />)} />
          </Route>
          {/* Static are the hardcoded pages, as these are using different layouts */}
          <Route element={<Static />}>
            <Route path="/payment-terms-public" element={SuspendPage(<Page />)} />
            <Route path="/guest-information" element={SuspendPage(<Page />)} />
            <Route path="/privacy-policy" element={SuspendPage(<Page />)} />
            <Route path="/cookies-policy" element={SuspendPage(<Page />)} />
            <Route path="/terms-and-conditions" element={SuspendPage(<Page />)} />
            <Route path="/faq" element={SuspendPage(<Page />)} />
            <Route path="/" element={SuspendPage(<Page />)} />
          </Route>
          <Route element={<Layout showHeader={false} />}>
            <Route path="/my-profile/setup" element={SuspendPage(<AccountSetup />)} />
            <Route path="/my-profile/member-select" element={SuspendPage(<MemberSelect />)} />
          </Route>
          <Route element={<Layout />}>
            <Route path="/my-profile" element={SuspendPage(<MyAccount />)} />
            <Route path="/ticket-payment/:id" element={SuspendPage(<TicketPayment />)} />
            <Route path="/switchclub/:club" element={SuspendPage(<SwitchingClub />)} />
            <Route path="/updates/:url" element={SuspendPage(<Page />)} />
            <Route path="/events/:url" element={SuspendPage(<Page />)} />
            <Route path="/:url" element={SuspendPage(<Page />)} />
            <Route path="*" element={SuspendPage(<NoMatch />)} />
          </Route>
        </Routes>
      </ExceptionHandler>
    </BrowserRouter>
  );
}

export default App;
