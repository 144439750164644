import React, { useEffect, useState } from "react";

import LazyLoad from "../Lazyload";
import Link from "../Link";
import { Text } from "../Elements";
import { cleanInput } from "../../helpers/input";

import style from "./style.module.scss";

export default function Input(props) {
    const {
        input: { onChange, onBlur, ...inputProps },
        placeholder = "",
        className = "",
        required = false,
        emptyValue = false,
        meta: { touched = false, error = false },
        disabled = false,
        label = "",
        icon = false,
        onIconClick = () => { },
        animationDisabled = false,
        type = "",
    } = props;

    const [isFocus, setFocus] = useState(false);
    const [value, setValue] = useState(cleanInput(props?.input?.value || ""));
    const [inputType, setInputType] = useState(type);
    const [inputIcon, setInputIcon] = useState(icon);

    useEffect(() => {
        if (emptyValue && !value.toString()) {
            onChange(emptyValue);
        }
    }, []);

    const handleFocusEvent = () => {
        if (isFocus === false) {
            setFocus(true);
        }
    };

    const handleBlurEvent = () => {
        if (isFocus === true) {
            setFocus(false);
        }
    };

    const handleChangeEvent = (e) => {
        onChange(cleanInput(e.target?.value || ""));
        setValue(value);
    };

    const handleIconClick = () => {
        if (inputType === "password") {
            setInputType("text");
            setInputIcon("fa-regular fa-eye");
        }
        if (inputType === "text") {
            setInputType("password");
            setInputIcon("fa-regular fa-eye-slash");
        }
        onIconClick();
    };


    return (
        <LazyLoad
            className={`${style.formGroup}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${isFocus ? " " + style.inputFocus : ""}${touched && !error ? " " + style.inputValid : ""}`}
            disabled={animationDisabled}
        >
            {label && (
                <Text
                    className={style.label}
                    text={`${label}${required ? ` *` : ""}`}
                />
            )}
            {type !== "textarea" && (
                <div className={`${style.inputfield}`}>
                    <span className={style.inputfieldContainer}>
                        <input
                            {...inputProps}
                            className={`${style.textbox}${icon ? ` ${style.hasIcon}` : ``}`}
                            placeholder={placeholder}
                            type={inputType}
                            onFocus={handleFocusEvent}
                            onBlur={(e) => {
                                onBlur(e);
                                setTimeout(() => handleBlurEvent());
                            }}
                            onChange={handleChangeEvent}
                            disabled={disabled}
                        />
                    </span>
                    {icon && type === "password" && (
                        <Link className={style.icon} onClick={handleIconClick} >
                            <i className={inputIcon} />
                        </Link>
                    )}
                    {icon && type !== "password" && (
                        <Link className={style.icon} onClick={onIconClick}>
                            <i className={icon}></i>
                        </Link>
                    )}
                </div>
            )}
            {type === "textarea" && (
                <div className={`${style.inputfield} ${style.textarea}`}>
                    <span className={style.inputfieldContainer}>
                        <textarea
                            {...inputProps}
                            className={style.textbox}
                            rows={5}
                            type={type}
                            placeholder={placeholder}
                            onFocus={handleFocusEvent}
                            onBlur={(e) => {
                                onBlur(e);
                                setTimeout(() => handleBlurEvent());
                            }}
                            onChange={handleChangeEvent}
                            disabled={disabled}
                        />
                    </span>
                </div>
            )}
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    );
}
