import React from "react";
import { getClubImage, getUserOtherClubs } from "../helpers/storage";
import style from './style.module.scss';
import { get } from "lodash";
import { useSelector } from "react-redux";
import Link from "../components/Link";

const SwitchClub = () => {
    const { salesforce_meta = {} } = useSelector((state) => get(state, 'storage.data', {}));
    const userClubs = getUserOtherClubs(salesforce_meta);
    return (
        <div className={style.switchClub}>
            <div className={style.item}>Switch Club</div>
            {Object.keys(userClubs).map((club) => {
                const name = get(userClubs, `${club}.name`);
                return (
                    <Link className={style.item} key={club} to={`/switchclub/${club}`}>
                        <div className={`${style.img} ${club}Img`}>
                            <img src={getClubImage(name)} alt={name} />
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}

export default SwitchClub;