import Cookies from 'universal-cookie';
import { fivehsLogo, maximesLogo, oswaldsLogo } from './images';
import { get } from 'lodash';
import { getNicosAttr } from './input';
import { isPA } from './hierarchy';
import { convertDateToUnix, dateTime, getTodayDate } from './format';

export const getClubs = () => {
    return {
        "maximes": {
            "name": "Maxime's",
            "currency": {
                "code": "USD",
                "symbol": getCurrencySymbol("USD"),
            },
            "website": process.env.REACT_APP_MAXIMES_WEBSITE,
            "color": "#0F3F7E",
            "timezone": "America/New_York",
        },
        "oswalds": {
            "name": "Oswald's",
            "currency": {
                "code": "GBP",
                "symbol": getCurrencySymbol("GBP"),
            },
            "website": process.env.REACT_APP_OSWALDS_WEBSITE,
            'color': "#000",
            "timezone": "UTC",
        },
        "fivehs": {
            "name": "5 Hertford Street",
            "currency": {
                "code": "GBP",
                "symbol": getCurrencySymbol("GBP"),
            },
            "website": process.env.REACT_APP_FIVEHS_WEBSITE,
            "color": "#62272E",
            "timezone": "UTC",
        },
    }
}

export const getClubImage = (club) => {
    let clubs = {
        "Maxime's": maximesLogo,
        "Oswald's": oswaldsLogo,
        "5 Hertford Street": fivehsLogo,
        "Nico's": oswaldsLogo,
    }
    return get(clubs, club);
}

export const getCurrentClub = () => {
    return getCookie("club") || process.env.REACT_APP_SITE;
}

export const getCurrentClubName = () => {
    return get(getClubs(), `${getCurrentClub()}.name`);
}

export const getCurrentClubCurrency = (type = "symbol") => {
    return get(getClubs(), `${getCurrentClub()}.currency.${type}`);
}

export const getClubAttr = (club = getCurrentClub(), attr = 'name') => {
    return get(getClubs(), `${club}.${attr}`);
}

export const getCurrencySymbol = (currency = "GBP") => {
    const currencies = {
        "GBP": "£",
        "USD": "$",
    }
    return get(currencies, currency);
}

export const getPaymentMethodType = (type = "") => {
    if (["directdebit_gb", "direct debit", "ach"].includes(type.toLowerCase())) {
        return "directdebit";
    }
    return "card";
}

export const getUserClubStatus = (userData, clubName = getCurrentClubName()) => {
    return get(userData, `Club_Membership_Relation__c.${clubName}.Status__c`);
}

export const getUserClubAttr = (userData, attribute, clubName = getCurrentClubName()) => {
    return get(userData, `Club_Membership_Relation__c.${clubName}.${attribute}`);
}

export const getCurrentMember = () => {
    return getCookie("current_member");
}

export const getCookie = (cookiename = "token", parseJson = false) => {
    // Get name followed by anything except a semicolon
    var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    cookiestring = decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
    if (parseJson && cookiestring) {
        cookiestring = JSON.parse(cookiestring);
    }
    return cookiestring || "";
}

export const setCookie = (value, cookiename = "token") => {
    const cookies = new Cookies();
    cookies.set(cookiename, value, {
        path: "/",
        secure: true,
        expires: 0,
        sameSite: 'Strict'
    });
}

export const deleteCookie = (cookiename = "token") => {
    const cookies = new Cookies();
    cookies.remove(cookiename, {
        path: "/",
        secure: true,
        sameSite: 'Strict'
    });
}

export const getPaymentMethodOptions = () => {
    if (getCurrentClub() === "maximes") {
        return [
            {
                "id": "ACH",
                "text": "ACH (US Bank Accounts Only)",
            },
            {
                "id": "Credit Card",
                "text": "Credit Card",
            }
        ]
    }
    return [
        {
            "id": "Direct Debit",
            "text": "Direct Debit (UK Bank Accounts Only)",
        },
        {
            "id": "Credit Card",
            "text": "Credit Card",
        }
    ];
}

export const isFiveHS = () => {
    return getCurrentClub() === "fivehs";
}

export const isOswalds = () => {
    return getCurrentClub() === "oswalds";
}

export const isMaximes = () => {
    return getCurrentClub() === "maximes";
}

export const getCurrentClubColor = () => {
    return get(getClubs(), `${getCurrentClub()}.color`);
}

export const getAchDBlabel = () => {
    if (isFiveHS() || isOswalds()) {
        return {
            "account-label": "Direct Debit GB & Card Management",
            "button-label": "Add/Update Direct Debit",
            "method-label": "Direct Debit GB",
            "detailed-label": "Direct Debit (UK Bank Accounts only)",
            "label": "Direct Debit",
        }
    }
    return {
        "account-label": "ACH & Card Management",
        "button-label": "Add/Update ACH",
        "method-label": "ACH",
        "label": "ACH",
        "detailed-label": "ACH (US Bank Accounts only)",
    };
}

export const isOswaldsAndNicos = (salesforce_meta) => {
    return (isOswalds() && !!getUserClubAttr(salesforce_meta, 'CardNo__c', getNicosAttr('name')));
}

export const isOswaldsAndNicosMember = (storage) => {
    const salesforce_meta = isPA(storage) ? get(storage, 'paMember.salesforce_meta', {}) : get(storage, 'data.salesforce_meta', {});
    return isOswaldsAndNicos(salesforce_meta);
}

export const getUserOtherClubs = (salesforce_meta) => {
    const clubs = getClubs();
    const userClubs = get(salesforce_meta, 'Club_Membership_Relation__c', {});
    let userOtherClubs = {};
    Object.keys(clubs).map((club) => {
        const name = get(clubs, `${club}.name`);
        if (club === getCurrentClub() || !get(userClubs, name)) {
            return null;
        }
        userOtherClubs[club] = get(clubs, club);
        return null;
    });
    return userOtherClubs;
}

export const getMembershipFields = (membership) => {
    let fields = [
        {
            'label': 'Status',
            'value': get(membership, `Status__c`, '')
        },
        {
            'label': "Today's Date",
            'value': dateTime(convertDateToUnix(getTodayDate())),
        },
        {
            'label': 'Member Number',
            'value': get(membership, `CardNo__c`, ''),
        },
        {
            'label': 'Membership Type',
            'value': get(membership, `Member_Type__c`, ''),
        },
        {
            'label': 'Join Date',
            'value': get(membership, `Join_Date__c`) ? dateTime(convertDateToUnix(get(membership, `Join_Date__c`)), '', 'UTC') : '',
        },
    ];

    const statusField = fields.shift();
    if (statusField.value === "Active") {
        fields.unshift(statusField);
    }
    return fields;
}