import { get } from 'lodash';
import moment from 'moment';
import { getClubs, getCurrentClub } from './storage';
const momentTimezone = require('moment-timezone');

export const getDefaultTimezone = () => {
    return get(getClubs(), `${getCurrentClub()}.timezone`);
}

export const getCurrentUnixTimeStamp = () => {
    return momentTimezone().unix();
}

export const dateTime = (d, format, timezone) => {
    if (!timezone) {
        timezone = getDefaultTimezone();
    }
    if (!d) {
        return "";
    }
    if (!format) {
        format = "Do MMMM YYYY";
    }
    return momentTimezone.tz(d * 1000, timezone).format(format);
}

export const convertDateToUnix = (dateText, timezone) => {
    if (dateText) {
        dateText = new Date(dateText);
    } else {
        dateText = new Date();
    }
    if (!timezone) {
        timezone = getDefaultTimezone();
    }
    return momentTimezone.tz(Math.floor(moment.unix(moment(dateText)).valueOf() / 1000000), timezone);
}

export const formatAddress = (address) => {
    let addText = [];
    if (get(address, 'street')) {
        addText.push(get(address, 'street'));
    }
    if (get(address, 'city')) {
        addText.push(get(address, 'city'));
    }
    if (get(address, 'postalCode')) {
        addText.push(get(address, 'postalCode'));
    }
    if (get(address, 'country')) {
        addText.push(get(address, 'country'));
    }
    if (addText.length <= 0) {
        return "Not Specified";
    } else {
        return addText.join(", ");
    }
}

export const formatNumber = (number) => {
    if (!number) {
        return 0;
    }
    number = parseFloat(number);
    return number.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export const formatTimeAgo = (unixTime) => {
    const time = moment.unix(unixTime);
    const now = moment();

    if (now.diff(time, 'hours') < 24) {
        return time.fromNow(); // e.g., "3 hours ago"
    } else if (now.diff(time, 'days') < 2 && now.day() !== time.day()) {
        return 'yesterday';
    } else {
        return time.format('MMM D, YYYY h:mm A'); // e.g., "Jul 4, 2023 3:15 PM"
    }
};

export const maskString = (str, unmaskedStartChars, unmaskedEndChars) => {
    // Ensure input is a string and parameters are numbers
    if (typeof str !== 'string' || typeof unmaskedStartChars !== 'number' || typeof unmaskedEndChars !== 'number') {
        throw new Error('Invalid input');
    }

    const strLength = str.length;

    // If the total of unmasked characters is greater than or equal to the string length, return the string as is
    if (unmaskedStartChars + unmaskedEndChars >= strLength) {
        return str;
    }

    // Create the masked part
    const maskedPartLength = strLength - (unmaskedStartChars + unmaskedEndChars);
    const maskedPart = '*'.repeat(maskedPartLength);

    // Form the final masked string
    const result = str.slice(0, unmaskedStartChars) + maskedPart + str.slice(-unmaskedEndChars);

    return result;
}

export const getNetSuiteFilterStartDate = () => {
    return convertDateToUnix('2024-09-01');
}

export const getTodayDate = () => {
    return moment().format('YYYY-MM-DDTHH:mm:ss');
}

export const addDate = (number, text) => {
    return moment().add(number, text).format('YYYY-MM-DDTHH:mm:ss');
}

export const timeDifference = (dateTime) => {
    return moment().diff(moment.unix(convertDateToUnix(dateTime)), 'hours');
}

export const getDayNumberFromUnixTimestamp = (timestamp = 0) => {
    return new Date(timestamp * 1000).getDay();
}